"use client";

import {
  Dialog,
  DialogContent,
  DialogTrigger,
  TextBody,
  TextHeadline,
  TextLabel,
  cn,
} from "@sourceful/design-system-v3";
import { useState } from "react";
import { SanityMenuItem } from "../../../../groq/siteHeaderConfig";
import StandardLink from "../../sanity/StandardLink";
import { renderImage } from "../primary-navigation/PrimaryNavigationMenuItem";

const BurgerMenuItem = ({ data }: { data: SanityMenuItem }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  if (!data.menuItems) {
    return (
      <StandardLink link={data.standardLink} className="block rounded-xs hover:text-ui-grey-700">
        <div className={cn("flex gap-12 py-12 border-b border-ui-grey-200", {})}>
          {data.linkImage && renderImage(data?.linkImage, "w-24 h-24")}
          <div className="flex flex-col gap-4">
            <p>{data.linkText}</p>
            {data.linkDescription && (
              <TextLabel size="medium" asChild>
                <p className="!text-brand-black font-regular">{data.linkDescription}</p>
              </TextLabel>
            )}
          </div>
        </div>
      </StandardLink>
    );
  }

  return (
    <Dialog open={menuOpen} onOpenChange={open => setMenuOpen(open)}>
      <DialogTrigger asChild>
        <StandardLink
          link={data.standardLink}
          className="block py-12 rounded-xs hover:text-ui-grey-700 border-b border-ui-grey-200"
          onClick={e => {
            e.preventDefault();
            setMenuOpen(true);
          }}
        >
          {data.linkText}
        </StandardLink>
      </DialogTrigger>
      <DialogContent className="w-screen h-screen max-w-none rounded-none">
        <TextBody size="small" weight="medium" asChild>
          <nav className="my-32 mx-20">
            <TextHeadline size="small" asChild>
              <p className="mb-16">{data.linkText}</p>
            </TextHeadline>
            <ul>
              {data.menuItems.map(menuItem => {
                return (
                  <li key={menuItem._key}>
                    <BurgerMenuItem data={menuItem} />
                  </li>
                );
              })}
            </ul>
          </nav>
        </TextBody>
      </DialogContent>
    </Dialog>
  );
};

export default BurgerMenuItem;
