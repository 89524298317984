export function getUrlFromTypeAndSlug(type: string, slug: string) {
  switch (type) {
    case "productMain":
    case "productPreset":
      return `/buy/${slug}`;
    case "productPresetPage":
      return `/buy/${slug}`;
    case "directPurchaseProductPage":
      return `/buy/direct/${slug}`;
    case "basicPage":
      return `/legal/${slug}`;
    case "shopIndustryPage":
      return `/${slug}`;
    case "productLeadGenPage":
      return `/buy/${slug}/lead-gen`;
    default:
      return `/`;
  }
}
