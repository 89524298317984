"use client";
import {
  Button,
  ButtonText,
  cn,
  Flex,
  Label,
  SingleLineInput,
  TextTitle,
} from "@sourceful/design-system-v3";
import React, { useRef } from "react";
import { useDebounce } from "react-use";
import { LoadingSpinner } from "../../../common/loading-spinner";
import { useSearchData } from "./hooks";
import { RecentSearches } from "./recent-searches";
import { SearchResults } from "./search-results";
import { SearchSuggestions } from "./search-suggestion";
import { useRecentSearches } from "./useRecentSearches";

export const Search = () => {
  const [searchText, setSearchText] = React.useState("");
  const [searchTextDebounced, setSearchTextDebounced] = React.useState("");

  useDebounce(
    () => {
      setSearchTextDebounced(searchText);
    },
    1000,
    [searchText]
  );

  const enabled = !!searchTextDebounced && searchTextDebounced === searchText;

  const { searchResults, isLoading, isError } = useSearchData(searchTextDebounced, enabled);

  const { ...recentConfig } = useRecentSearches({
    onSelect: (text: string) => {
      setSearchText(text);
    },
  });

  const handleItemClick = () => {
    recentConfig.addRecentSearch(searchTextDebounced);
  };

  const searchInputRef = useRef<HTMLInputElement>(null);

  const loading = (isLoading || searchText !== searchTextDebounced) && !!searchText;

  return (
    <Flex
      className="items-stretch w-full max-w-7xl mx-auto md:py-40 text-brand-black"
      direction={"column"}
    >
      <TextTitle size="medium" asChild>
        <h2>Search</h2>
      </TextTitle>

      <Flex className="relative">
        <Label className={"sr-only"} labelFor="search">
          Search
        </Label>

        <SingleLineInput
          ref={searchInputRef}
          id="search"
          placeholder="Keywords e.g. Mailer Box"
          className={"flex-auto"}
          value={searchText}
          onChange={e => setSearchText(e.target.value)}
        />
        <Button
          size={"md"}
          variant={"tertiary"}
          className={cn("text-ui-blue-500 underline absolute right-0 hover:bg-transparent", {
            invisible: !searchText,
          })}
          onClick={() => {
            setSearchText("");
            setSearchTextDebounced("");

            searchInputRef.current?.focus();
          }}
        >
          <ButtonText>Clear</ButtonText>
        </Button>
      </Flex>
      {isError && <>There was an error</>}
      {loading ? (
        <LoadingSpinner />
      ) : (
        <SearchResults
          products={searchResults.products}
          articles={searchResults.articles}
          onItemClick={handleItemClick}
          searchText={searchTextDebounced}
        />
      )}
      {!searchText && <RecentSearches {...recentConfig} />}
      {!searchText && <SearchSuggestions onSelect={setSearchText} />}
    </Flex>
  );
};
