import { ArticleSearchResult } from "@groq/searchQuery";
import { TextBody, TextLabel } from "@sourceful/design-system-v3";
import Link from "next/link";
import { FC } from "react";

interface SearchArticleProps {
  article: ArticleSearchResult;
  onArticleClick: () => void;
}

export const SearchArticle: FC<SearchArticleProps> = ({ article, onArticleClick }) => {
  return (
    <li className={"[&_[data-article-name]]:hover:underline"}>
      <Link
        href={`/knowledge-hub/${article.slug}`}
        onClick={onArticleClick}
        className="flex flex-col gap-8 focus:focus-ring !outline-offset-4 rounded-xs"
        prefetch={false}
      >
        <TextBody size={"small"} weight={"medium"} data-article-name>
          {article.name}
        </TextBody>
        <TextLabel size={"large"} weight={"regular"} className={"text-ui-grey-400"}>
          {article.heading}
        </TextLabel>
      </Link>
    </li>
  );
};
