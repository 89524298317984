"use client";

import {
  Button,
  Dialog,
  DialogContent,
  DialogTrigger,
  IconNavigationSearch,
  Tooltip,
  TooltipContent,
  TooltipTitle,
  TooltipTrigger,
} from "@sourceful/design-system-v3";
import React, { useState } from "react";
import { Search } from "./Search";

interface SearchProps {}

export const SearchAction = React.forwardRef<HTMLButtonElement, SearchProps>(
  ({ ...props }, ref) => {
    const [visible, setVisible] = useState(false);

    return (
      <Dialog open={visible} onOpenChange={open => setVisible(open)}>
        <Tooltip>
          <TooltipTrigger asChild>
            <DialogTrigger asChild>
              <Button
                aria-label="Open search"
                variant="tertiary"
                size="md"
                layout="iconOnly"
                {...props}
                ref={ref}
                className="text-inherit hover:text-ui-darkest duration-300"
              >
                <IconNavigationSearch />
              </Button>
            </DialogTrigger>
          </TooltipTrigger>
          <TooltipContent>
            <TooltipTitle text={"Open search"} />
          </TooltipContent>
        </Tooltip>
        <DialogContent className="w-screen h-full overflow-y-auto max-w-none rounded-none bg-ui-lightest">
          <Search />
        </DialogContent>
      </Dialog>
    );
  }
);
