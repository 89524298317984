import env from "@lib/utils/env";
import { ClientConfig, createClient } from "@sanity/client";
import imageUrlBuilder from "@sanity/image-url";
import { ImageUrlBuilder } from "@sanity/image-url/lib/types/builder";
import { SanityImageSource } from "@sanity/image-url/lib/types/types";
import { Image } from "@sourceful/shared-types";

const SANITY_DATASET = env("SANITY_DATASET");
const SANITY_PROJECT_ID = env("SANITY_PROJECT_ID");
const SANITY_PREVIEW_TOKEN = env("SANITY_PREVIEW_TOKEN");
const SANITY_CLIENT_API_VERSION = "2021-10-21";
const useSanitySvgHack = env("NEXT_PUBLIC_USE_SANITY_SVG_HACK") === "true";

const config: ClientConfig = {
  /**
   * Find your project ID and dataset in `sanity.json` in your studio project.
   * These are considered “public”, but you can use environment variables
   * if you want differ between local dev and production.
   *
   * https://nextjs.org/docs/basic-features/environment-variables
   **/
  dataset: SANITY_DATASET || "develop",
  projectId: SANITY_PROJECT_ID || "9ebohwu7",
  apiVersion: SANITY_CLIENT_API_VERSION,
};

if (!config.projectId) {
  throw Error("The Project ID is not set. Check your environment variables.");
}
if (!config.dataset) {
  throw Error("The dataset name is not set. Check your environment variables.");
}

export const urlSVGFix = (url: string | null) => {
  if (url) {
    if (url.indexOf(".svg") > -1) {
      return url.replace(`/${SANITY_DATASET}/`, "/develop/");
    }
  }
  return url;
};

export const getSanityImgUrl = (
  assetRef: string,
  transforms?: (baseTransforms: ImageUrlBuilder) => ImageUrlBuilder,
  quality: number = 100
) => {
  if (!assetRef) return "";
  const base = urlFor(assetRef);
  const withCustomTransforms = transforms ? transforms(base) : base;
  const final = withCustomTransforms.quality(quality).auto("format");
  return useSanitySvgHack ? urlSVGFix(final.url()) || "" : final.url() || "";
};

export type ImageDimensions = {
  width: number;
  height: number;
  aspectRatio: number;
};

export type ImageData = {
  url: string;
  altText: string;
  dimensions: ImageDimensions;
};

export function getImageData(
  image: Image,
  transforms?: (baseTransforms: ImageUrlBuilder) => ImageUrlBuilder,
  fallbackAltText?: string
): ImageData | null {
  if (!image) {
    return null;
  }

  const dimensions = getImageDimensions(image.asset._ref);

  return {
    url: getSanityImgUrl(image.asset._ref, transforms) ?? "",
    altText: image.asset.altText ?? fallbackAltText ?? "",
    dimensions: {
      width: dimensions?.width ?? 0,
      height: dimensions?.height ?? 0,
      aspectRatio: dimensions?.aspectRatio ?? 0,
    },
  };
}

export function getImageDimensions(assetRef: string): ImageDimensions | undefined {
  if (!assetRef) {
    return;
  }

  if (assetRef.indexOf("-") === -1 || assetRef.indexOf("x") === -1) {
    return;
  }

  const dimensions = assetRef.split("-")[2];
  const [width, height] = dimensions.split("x").map(Number);

  if (!width || !height || Number.isNaN(width) || Number.isNaN(height)) {
    return;
  }

  return {
    width,
    height,
    aspectRatio: width / height,
  };
}

// Set up the client for fetching data in the getProps page functions
export const sanityClient = createClient({
  ...config,
  useCdn: true,
  apiVersion: SANITY_CLIENT_API_VERSION,
});

// Set up a preview client with serverless authentication for drafts
// Ideally it shouldnt be possible to call this in client/browser code
export const previewClient = createClient({
  ...config,
  useCdn: false,
  token: SANITY_PREVIEW_TOKEN,
  apiVersion: SANITY_CLIENT_API_VERSION,
});

// Same as sanityClient at the moment but keeping in just for reference
// export const browserClient = createClient({ ...config, useCdn: true });

export const browserPreviewClient = createClient({
  ...config,
  useCdn: false,
  withCredentials: true,
  apiVersion: SANITY_CLIENT_API_VERSION,
});

// Helper function for easily switching between normal client and preview client
export const getClient = (usePreview: boolean = false, browser: boolean = false) => {
  if (browser && usePreview) {
    return browserPreviewClient;
  }

  if (usePreview) {
    return previewClient;
  }

  return sanityClient;
};

const builder = imageUrlBuilder(getClient());
export const urlFor = (source: SanityImageSource) => builder.image(source);
