import { RecentSearchesProps } from "./recent-searches";
import { useLocalStorage } from "react-use";

interface UseRecentSearchConfig {
  onSelect: (text: string) => void;
}

type UseRecentSearchResult = RecentSearchesProps & {
  addRecentSearch: (text: string) => void;
};

const unique = (value: string, index: number, self: Array<string>) => self.indexOf(value) === index;

export const useRecentSearches = ({ onSelect }: UseRecentSearchConfig): UseRecentSearchResult => {
  const [recentSearches = [], setRecentSearches] = useLocalStorage<Array<string>>(
    "recent-searches",
    []
  );

  return {
    recentSearches: recentSearches,
    addRecentSearch: (text: string) => {
      const newRecentSearches = [text, ...recentSearches].filter(unique);
      setRecentSearches(newRecentSearches);
    },
    onSelectRecentSearch: (text: string) => onSelect(text),
    onClearAllRecentSearches: () => {
      setRecentSearches([]);
    },
    onClearRecentSearch: (text: string) => {
      const newRecentSearches = recentSearches.filter(recentSearch => recentSearch !== text);
      setRecentSearches(newRecentSearches);
    },
  };
};
