"use client";

import { cn, cva } from "@sourceful/design-system-v3";
import React from "react";
import { useIntersection } from "react-use";

interface HeaderContainerProps {
  children?: React.ReactNode;
  /**
   * Force text to be dark-coloured.
   */
  darkText?: boolean;
}

const headerContainerCva = cva("flex w-full justify-center", {
  variants: {
    textColourStyle: {
      darkText:
        "[&>[data-header-backdrop]]:bg-ui-alpha-lightest-12 [&_[data-header-logo]]:text-ui-darkest [&_[data-header-primary-navigation]]:text-ui-darkest [&_[data-header-actions]]:text-ui-darkest [&_[data-burger-menu-button]]:text-ui-darkest",
      default: "",
    },
  },
});

export default function HeaderContainer({
  children,
  darkText = false,
}: Readonly<HeaderContainerProps>) {
  const intersectionRef = React.useRef(null);

  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: "-62px 0px 0px 0px", // 56px header + 8px margin
    threshold: 0,
  });

  const useDarkText = darkText || !(intersection?.isIntersecting ?? true);

  return (
    <div
      ref={intersectionRef}
      className={cn(
        headerContainerCva({
          textColourStyle: useDarkText ? "darkText" : "default",
        })
      )}
    >
      {children}
    </div>
  );
}
