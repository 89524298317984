import { usePageContext } from "@app/providers/PageProvider/PageProvider";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { ErrorStatus } from "@app/providers/ErrorProvider/ErrorProvider";
import { ArticleSearchResult, ProductSearchResult } from "@groq/searchQuery";
import { useHandleQueryError } from "@app/hooks/useHandleQueryError";

export interface SearchResults {
  empty: boolean;
  noResults: boolean;
  products: ProductSearchResult[];
  articles: ArticleSearchResult[];
}

const defaultSearchState: SearchResults = {
  empty: true,
  noResults: false,
  products: [],
  articles: [],
};

export interface UseSearchDataResults {
  searchResults: SearchResults;
  isLoading: boolean;
  isError: boolean;
}

export const useSearchData = (searchText: string, enabled: boolean): UseSearchDataResults => {
  const { locale } = usePageContext();

  const {
    data: searchResults = defaultSearchState,
    error,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["/api/search", searchText, locale],
    enabled,
    queryFn: async () => {
      const res = await axios.get(
        `/api/search?q=${encodeURIComponent(searchText)}&locale=${locale}`,
        { timeout: 5000 }
      );

      if ("products" in res.data || "articles" in res.data) {
        let data: SearchResults = {
          ...(res.data as SearchResults),
        };

        data.noResults =
          searchText !== "" && data.articles.length === 0 && data.products.length === 0;
        data.empty = searchText === "" && data.articles.length === 0 && data.products.length === 0;

        return data;
      } else {
        return defaultSearchState;
      }
    },
  });

  useHandleQueryError({
    error,
    message: "Error searching for products and articles",
    scope: "Search",
    status: ErrorStatus.local,
    type: "report",
  });

  return { searchResults, isLoading, isError };
};
