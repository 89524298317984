import { ArticleSearchResult, ProductSearchResult } from "@groq/searchQuery";
import { TextBody } from "@sourceful/design-system-v3";
import { FC } from "react";
import { SearchArticle } from "./SearchArticle";
import { SearchHairline } from "./SearchHairline";
import { SearchProduct } from "./SearchProduct";

export interface SearchResultsProps {
  products: ProductSearchResult[];
  articles: ArticleSearchResult[];
  searchText: string;
  onItemClick: () => void;
}

export const SearchResults: FC<SearchResultsProps> = ({
  products,
  articles,
  onItemClick,
  searchText,
}) => {
  if (products.length === 0 && articles.length === 0) {
    return null;
  }

  return (
    <div>
      <TextBody size="small" asChild>
        <p>
          {products.length + articles.length} result(s) for <strong>{searchText}</strong>
        </p>
      </TextBody>

      {products.length > 0 && (
        <>
          <SearchHairline />
          <div className="flex flex-col gap-16">
            <TextBody size="large" asChild>
              <h3>Shop</h3>
            </TextBody>

            <ul className="flex flex-col space-y-32">
              {products.map(product => (
                <SearchProduct
                  key={product._id}
                  product={product}
                  onProductClick={onItemClick}
                  fallbackImage={products.find(p => p._id === product.productMainId)?.image}
                />
              ))}
            </ul>
          </div>
        </>
      )}
      {articles.length > 0 && (
        <>
          <SearchHairline />
          <div className="flex flex-col gap-16">
            <TextBody size="large" asChild>
              <h3>Articles</h3>
            </TextBody>

            <ul className="flex flex-col space-y-32">
              {articles.map(article => (
                <SearchArticle key={article.slug} article={article} onArticleClick={onItemClick} />
              ))}
            </ul>
          </div>
        </>
      )}
    </div>
  );
};
