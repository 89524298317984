import { Button, ButtonText, IconNavigationSearch } from "@sourceful/design-system-v3";
import { FC } from "react";

interface SearchSuggestionItemProps {
  item: { title: string };
  onSelect: (title: string) => void;
}

export const SearchSuggestionItem: FC<SearchSuggestionItemProps> = ({ item, onSelect }) => {
  return (
    <li>
      <Button
        variant={"tertiary"}
        onClick={() => onSelect(item.title)}
        layout={"iconLeading"}
        size="md"
      >
        <IconNavigationSearch />
        <ButtonText>{item.title}</ButtonText>
      </Button>
    </li>
  );
};
