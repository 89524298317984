import env from "@lib/utils/env";
import { setupCache } from "axios-cache-adapter";
import { createAxiosClient } from "@sourceful/shared-utils/api";

export const cache = setupCache({
  maxAge: 15 * 60 * 1000,
  exclude: {
    methods: ["put", "patch", "delete"],
  },
  limit: 100,
});

const getAxiosClient = createAxiosClient(env("API_ORIGIN"), error => {
  if (JSON.stringify(error.response?.data)?.toLowerCase()?.includes("login required")) {
    window.location.href = "/redirect-to-login";
  }
});

export default getAxiosClient;
