"use client";

import StandardLink from "@app/components/sanity/StandardLink";
import { useWindowSize } from "@react-hook/window-size";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
  TextBody,
  TextLabel,
  cn,
} from "@sourceful/design-system-v3";
import { Image } from "@sourceful/shared-types";
import { useEffect, useRef, useState } from "react";
import { SanityMenuItem } from "../../../../groq/siteHeaderConfig";
import { ImageDimensions, getImageDimensions, getSanityImgUrl } from "../../../../lib/sanity";

interface PrimaryNavigationMenuItemProps {
  data: SanityMenuItem;
}

export default function PrimaryNavigationMenuItem({
  data: menuItem,
}: PrimaryNavigationMenuItemProps) {
  const [visible, setVisible] = useState(false);

  const [width] = useWindowSize();

  // Store ref for our LI element
  const ref = useRef<HTMLLIElement>(null);

  useEffect(() => {
    if (width > 0) {
      setVisible(false);
    }
  }, [width]);

  const onMouseEnter = () => {
    setVisible(true);
  };

  if (!menuItem.menuItems) {
    return (
      <li className={cn({ "md:hidden": menuItem.burgerMenuOnly })} ref={ref}>
        <StandardLink
          link={menuItem.standardLink}
          variant="header"
          tabIndex={menuItem.burgerMenuOnly ? -1 : 0}
        >
          {menuItem.linkText}
        </StandardLink>
      </li>
    );
  }

  return (
    // Popovers were hanging around until the user interacted with the page again, click or focus elsewhere
    // Now we hide on mouse leave as the LI is now our popover content portal
    // We use a :before to help keep a large 'hit area' for the mouse to stay in
    <li
      className={cn("relative before:absolute before:-inset-16 before:-bottom-32", {
        "md:hidden": menuItem.burgerMenuOnly,
      })}
      onMouseLeave={() => {
        setVisible(false);
      }}
      ref={ref}
    >
      <Popover
        open={visible}
        onOpenChange={open => {
          setVisible(open);
        }}
      >
        <PopoverTrigger onMouseEnter={onMouseEnter} asChild>
          <StandardLink
            link={menuItem.standardLink}
            variant="header"
            tabIndex={menuItem.burgerMenuOnly ? -1 : 0}
            onClick={() => {
              setVisible(true);
            }}
            className="relative"
            data-cy={`primary-navigation-${menuItem._key}`}
          >
            {menuItem.linkText}
          </StandardLink>
        </PopoverTrigger>
        <PopoverContent
          close={false}
          sideOffset={16}
          className="shadow-hi"
          //container={ref.current}
        >
          <div className="flex flex-col py-20">
            {menuItem.menuItems &&
              menuItem.menuItems.map((subMenuItem, index) => {
                const isLast = index === menuItem.menuItems.length - 1;
                return (
                  <StandardLink
                    key={subMenuItem._key}
                    link={subMenuItem.standardLink}
                    tabIndex={menuItem.burgerMenuOnly ? -1 : 0}
                    className="mx-20 px-4 rounded-xs text-brand-black hover:text-ui-grey-700"
                  >
                    <div
                      className={cn("flex gap-12 py-12 border-b border-ui-grey-200", {
                        "border-b-0": isLast,
                      })}
                    >
                      {subMenuItem.linkImage && renderImage(subMenuItem?.linkImage, "w-24 h-24")}
                      <div className="flex flex-col gap-4">
                        <TextBody size="small" weight={"medium"} asChild>
                          <p>{subMenuItem.linkText}</p>
                        </TextBody>
                        <TextLabel size="medium" asChild>
                          <p className="!text-brand-black">{subMenuItem.linkDescription}</p>
                        </TextLabel>
                      </div>
                    </div>
                  </StandardLink>
                );
              })}
          </div>
        </PopoverContent>
      </Popover>
    </li>
  );
}

export function renderImage(logoImage: Image, className?: string) {
  let imageUrl = "",
    imageDimensions: ImageDimensions | undefined;

  if (logoImage?.asset?._ref) {
    // NOTE: This is a problem getSanityImageUrl ends up calling urlFor
    // which in turn calls getClient() which doesnt have any context of preview/browser.
    // This means we end up with Sanity client api stuff in our bundles
    imageUrl = getSanityImgUrl(logoImage.asset._ref, base => base.width(50).height(50));
    imageDimensions = getImageDimensions(logoImage.asset._ref);
  }

  if (!imageUrl) {
    return null;
  }

  return (
    <img
      src={imageUrl}
      alt={logoImage?.asset.altText ?? ""}
      width={imageDimensions?.width}
      height={imageDimensions?.height}
      className={className}
      loading="lazy"
    />
  );
}
