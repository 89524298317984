"use client";

import { useOrdersCount } from "@app/hooks/useOrdersCount";
import { useAuthentication } from "@app/providers/AuthenticationProvider/AuthenticationProvider";
import { handleSignIn } from "@app/providers/AuthenticationProvider/helpers/handleSignIn";
import { useWindowSize } from "@react-hook/window-size";
import {
  Button,
  ButtonText,
  IconArrowFastFastDown,
  IconNavigationPeople,
  IconNavigationUser,
  IconShoppingCartEmpty,
  Popover,
  PopoverContent,
  PopoverTrigger,
  TextBody,
  screens,
} from "@sourceful/design-system-v3";
import React, { useEffect, useRef, useState } from "react";
import useMedia from "react-use/lib/useMedia";
import AccountMenuItem from "./AccountMenuItem";

interface AccountMenuProps {}

const AccountMenu = React.forwardRef<HTMLButtonElement, AccountMenuProps>(({ ...props }, ref) => {
  const [visible, setVisible] = useState(false);

  const { isAuthenticated, logout, loginWithRedirect } = useAuthentication();

  const { orderCount } = useOrdersCount();

  const [width] = useWindowSize();

  const isMd = useMedia(`(min-width: ${screens.md})`, true);

  useEffect(() => {
    if (width > 0) {
      setVisible(false);
    }
  }, [width]);

  const onMouseEnter = () => {
    // Only set to visible on larger screens
    if (isMd) {
      setVisible(true);
    }
  };

  const dropdownLinks = [
    {
      _key: "1",
      title: "Account",
      icon: <IconNavigationUser className="w-24 h-24 text-ui-blue-600" />,
      url: "/account",
    },
    {
      _key: "2",
      title: "Orders",
      icon: <IconShoppingCartEmpty className="w-24 h-24 text-ui-blue-600" />,
      url: "/account/orders",
      count: orderCount,
    },
    {
      _key: "3",
      title: "Sign out",
      icon: <IconArrowFastFastDown className="w-24 h-24 text-ui-blue-600" />,
      onClick: () => {
        logout({
          logoutParams: {
            returnTo: window.location.origin,
          },
        });
      },
      count: 0,
    },
  ];

  const popoverContentContainerRef = useRef<HTMLDivElement>(null);

  return (
    <div
      ref={popoverContentContainerRef}
      onMouseLeave={() => {
        setVisible(false);
      }}
    >
      <Popover
        open={visible}
        onOpenChange={open => {
          setVisible(open);
        }}
      >
        <PopoverTrigger onMouseEnter={onMouseEnter} asChild>
          <Button
            data-cy="account-menu-button"
            ref={ref}
            variant="tertiary"
            size="md"
            layout="iconOnly"
            {...props}
            className={`text-inherit 
            hover:text-ui-darkest 
            duration-300 disabled:bg-transparent 
            disabled:text-inherit disabled:opacity-50
            relative hover:before:absolute hover:before:-inset-8 hover:before:-bottom-32`}
          >
            <IconNavigationPeople title="Account" />
          </Button>
        </PopoverTrigger>
        <PopoverContent close={false} sideOffset={16} fullScreen="until-md">
          <div className="flex flex-col gap-12 p-24 w-full min-w-[250px] text-brand-black">
            <TextBody size="medium" weight="medium" asChild>
              {/* TODO: Localise */}
              <p>Account</p>
            </TextBody>
            {!isAuthenticated && (
              <Button
                data-cy="login-button"
                variant="primary"
                size="md"
                onClick={() => {
                  handleSignIn(loginWithRedirect, { returnTo: "/" });
                }}
              >
                {/* TODO: Localise */}
                <ButtonText>Log in / Sign up</ButtonText>
              </Button>
            )}

            {isAuthenticated && (
              <ul>
                {dropdownLinks.map((link, index) => {
                  return (
                    <li key={link._key}>
                      <AccountMenuItem
                        text={link.title}
                        link={link.url}
                        icon={link.icon}
                        count={link.count}
                        isLast={index === dropdownLinks.length - 1}
                        onClick={link.onClick}
                      />
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
});

export default AccountMenu;
