import { useAuthentication } from "@app/providers/AuthenticationProvider/AuthenticationProvider";
import { ErrorStatus } from "@app/providers/ErrorProvider/ErrorProvider";
import { GetTokenSilentlyOptions } from "@auth0/auth0-react";
import getAxiosClient from "@lib/getAxiosClient";
import { useQuery } from "@tanstack/react-query";
import { apiConfig } from "apiConfig";
import { useHandleQueryError } from "./useHandleQueryError";

const ERROR_SCOPE = "Orders";

export const useOrdersCount = (): {
  orderCount: number | undefined;
  ordersLoading: boolean;
  error: any;
} => {
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuthentication();

  const {
    data: orderCount,
    error,
    isLoading: ordersLoading,
  } = useQuery({
    queryKey: ["order_count", isLoading, isAuthenticated],
    queryFn: () => fetchOrderCount(getAccessTokenSilently),
    gcTime: 0,
    enabled: !isLoading && isAuthenticated,
  });

  useHandleQueryError({
    error,
    message: "Unable to fetch order count",
    scope: ERROR_SCOPE,
    status: ErrorStatus.warning,
  });

  return { orderCount: orderCount, ordersLoading, error };
};

const fetchOrderCountForUser = async (token: string): Promise<number> => {
  const client = getAxiosClient(
    token,
    {
      baseURL: apiConfig.orderServiceApiOrigin,
    },
    {}
  );

  const {
    data: { data },
  } = await client.get("/client-order-access/order_count");

  return data?.order_service_order_aggregate?.aggregate?.count || 0;
};

const fetchOrderCount = async (getAccessTokenSilently: {
  (options?: GetTokenSilentlyOptions | undefined): Promise<string>;
}) => {
  try {
    const token = await getAccessTokenSilently();
    const res = await fetchOrderCountForUser(token);
    return res;
  } catch (error) {
    throw error;
  }
};
