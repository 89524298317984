import { TextBody } from "@sourceful/design-system-v3";
import { FC } from "react";
import { SearchSuggestionItem } from "./SearchSuggestionItem";

export interface SearchSuggestionsProps {
  onSelect: (title: string) => void;
}

export const SearchSuggestions: FC<SearchSuggestionsProps> = ({ onSelect }) => {
  const suggestions = [
    { title: "Mailer Box" },
    { title: "Sourceful Climate" },
    { title: "FSC" },
    { title: "Recycled Mailer" },
  ];
  return (
    <div className="flex flex-col gap-16">
      <TextBody size="medium" asChild>
        <h3>Suggested</h3>
      </TextBody>
      <ul>
        {suggestions.map(item => (
          <SearchSuggestionItem key={item.title} item={item} onSelect={onSelect} />
        ))}
      </ul>
    </div>
  );
};
