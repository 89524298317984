import env from "@lib/utils/env";
import { CloudBucketFolders } from "@sourceful/shared-utils/cdn-utils";

function loadOverrides() {
  try {
    const overrides = require("./overrides");
    return overrides.default;
  } catch (_error) {
    return null;
  }
}
export interface CloudBucketConfig {
  baseUrl: string;
  publicAssetsUrl: string;
  thumbnailAssetsUrl: string;
  folders: CloudBucketFolders;
}

export interface ApiConfig {
  apiOrigin: string;
  imageGeneratorOrigin: string;
  gcsUploaderOrigin: string;
  extractorOrigin: string;
  productCloudApiOrigin: string;
  blueprintApiOrigin: string;
  cloudBucket: CloudBucketConfig;
  cloudBucketThumbnails: string;
  orderServiceApiOrigin: string;
  engineApiOrigin: string;
  packagingInspirationApiOrigin: string;
  messageServiceApiOrigin: string;
  identityAccessManagementApiOrigin: string;
  productCatalogueApiOrigin: string;
  gtmSite: string;
  recaptchaSiteKey: string;
  springUrl: string;
  springSecretApiKey: string;
}

export type Overrides = Partial<ApiConfig>;
const overrides: Overrides = loadOverrides();

export const apiConfig: ApiConfig = {
  gtmSite: env("GTM_SITE"),
  apiOrigin: env("API_ORIGIN"),
  imageGeneratorOrigin:
    overrides?.imageGeneratorOrigin || env("API_ORIGIN") + "/product-cloud-image-generator",
  gcsUploaderOrigin: overrides?.gcsUploaderOrigin || env("API_ORIGIN"),
  extractorOrigin: overrides?.extractorOrigin || env("API_ORIGIN"),
  productCloudApiOrigin:
    overrides?.productCloudApiOrigin || env("API_ORIGIN") + "/product-cloud-api",
  blueprintApiOrigin: overrides?.blueprintApiOrigin || env("API_ORIGIN") + "/blueprint-service-api",
  cloudBucket: overrides?.cloudBucket || {
    baseUrl: env("CLOUD_BUCKET"),
    publicAssetsUrl: env("CLOUD_BUCKET") + env("CLOUD_BUCKET_PUBLIC_ASSETS"),
    thumbnailAssetsUrl: env("CLOUD_BUCKET") + env("CLOUD_BUCKET_THUMBNAIL_ASSETS"),
    folders: {
      publicAssets: env("CLOUD_BUCKET_PUBLIC_ASSETS"),
      thumbnailAssets: env("CLOUD_BUCKET_THUMBNAIL_ASSETS"),
    },
  },
  cloudBucketThumbnails:
    overrides?.cloudBucketThumbnails || env("CLOUD_BUCKET") + env("CLOUD_BUCKET_THUMBNAIL_ASSETS"),
  orderServiceApiOrigin: overrides?.orderServiceApiOrigin || env("API_ORIGIN") + "/order-service",
  engineApiOrigin: overrides?.engineApiOrigin || env("API_ORIGIN") + "/engine-api",
  messageServiceApiOrigin:
    overrides?.messageServiceApiOrigin || env("API_ORIGIN") + "/message-service",
  identityAccessManagementApiOrigin:
    overrides?.identityAccessManagementApiOrigin ||
    env("API_ORIGIN") + "/identity-access-management",
  productCatalogueApiOrigin:
    overrides?.productCatalogueApiOrigin || env("API_ORIGIN") + "/product-catalogue",
  recaptchaSiteKey: env("RECAPTCHA_SITE_KEY"),
  packagingInspirationApiOrigin:
    overrides?.packagingInspirationApiOrigin || env("API_ORIGIN") + "/packaging-inspiration-api",
  springUrl: env("NEXT_PUBLIC_SPRING_URL") || "",
  springSecretApiKey: env("SPRING_SECRET_API_KEY"),
};
