import React, { FC } from "react";
import { Flex, IconSystemLoading } from "@sourceful/design-system-v3";

interface LoadingSpinnerProps {}

export const LoadingSpinner: FC<LoadingSpinnerProps> = () => {
  return (
    <Flex className="justify-center">
      <IconSystemLoading className={"animate-spin"} />
    </Flex>
  );
};
