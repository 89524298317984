import { ProductSearchResult } from "@groq/searchQuery";
import { getSanityImgUrl } from "@lib/sanity";
import { TextBody, TextLabel } from "@sourceful/design-system-v3";
import { Image } from "@sourceful/shared-types";
import Link from "next/link";
import { FC } from "react";

interface SearchProductProps {
  product: ProductSearchResult;
  onProductClick: () => void;
  fallbackImage?: Image;
}

export const SearchProduct: FC<SearchProductProps> = ({
  product,
  onProductClick,
  fallbackImage,
}) => {
  return (
    <li className={"[&_[data-product-name]]:hover:underline"}>
      <Link
        href={`/buy/${product.slug}`}
        onClick={onProductClick}
        className="flex gap-16 focus:focus-ring !outline-offset-4 rounded-xs"
        prefetch={false}
      >
        <div className={"bg-ui-grey-200 w-80 h-80 min-w-80 min-h-8 rounded-md"}>
          {product.image && (
            <img
              src={getSanityImgUrl(product.image.asset._ref, base => base.width(150).height(150))}
              alt={""}
            />
          )}
          {!product.image && fallbackImage && (
            <img
              src={getSanityImgUrl(fallbackImage.asset._ref, base => base.width(150).height(150))}
              alt={""}
            />
          )}
        </div>
        <div className={"flex flex-col gap-8"}>
          <TextBody size={"small"} weight={"medium"} data-product-name>
            {product.name}
          </TextBody>
          {product.shortDescription && (
            <TextLabel size={"large"} weight={"regular"} className={"text-ui-grey-600"}>
              {product.shortDescription}
            </TextLabel>
          )}
          {!product.shortDescription && product.description && (
            <TextLabel size={"large"} weight={"regular"} className={"text-ui-grey-600"}>
              {product.description}
            </TextLabel>
          )}
          <TextLabel size={"large"} weight={"regular"} className={"text-ui-grey-400"}>
            /buy/{product.slug}
          </TextLabel>
        </div>
      </Link>
    </li>
  );
};
