import { VariantProps, cn, cva } from "@sourceful/design-system-v3";
import { SanityStandardLink } from "@sourceful/shared-types";
import Link from "next/link";
import React from "react";
import env from "../../../lib/utils/env";
import { getUrlFromTypeAndSlug } from "../../../lib/utils/urlHelper";

const BASE_URL = env("BASE_URL");

const standardLinkCva = cva("transition-colors duration-300 focus-visible:focus-ring", {
  variants: {
    variant: {
      default: "",
      header: "!outline-offset-4 rounded-sm",
      footer: "!outline-offset-4 rounded-sm hover:text-ui-green-500",
    },
  },
  defaultVariants: {
    variant: "default",
  },
});

interface StandardLinkProps
  extends React.HTMLAttributes<HTMLAnchorElement>,
    VariantProps<typeof standardLinkCva> {
  link: SanityStandardLink;
  children?: React.ReactNode;
  className?: string;
}

const StandardLink = React.forwardRef<HTMLAnchorElement, StandardLinkProps>(
  ({ link, variant, className, children, ...props }, ref) => {
    const { internalLink, externalLink } = link;

    if (externalLink && externalLink.link) {
      if (externalLink.link.startsWith("/")) {
        return (
          <Link passHref href={`${BASE_URL}${externalLink.link}`} legacyBehavior prefetch={false}>
            <a className={cn(standardLinkCva({ variant, className }))} {...props} ref={ref}>
              {children}
            </a>
          </Link>
        );
      } else {
        return (
          <a
            href={externalLink.link}
            target={externalLink.newWindow ? "_blank" : undefined}
            rel={externalLink.newWindow ? "noreferrer" : undefined}
            className={cn(standardLinkCva({ variant, className }))}
            {...props}
            ref={ref}
          >
            {children}
          </a>
        );
      }
    }

    if (internalLink) {
      return (
        <Link
          passHref
          href={`${BASE_URL}${getUrlFromTypeAndSlug(internalLink._type, internalLink.slug)}`}
          legacyBehavior
          prefetch={false}
        >
          <a className={cn(standardLinkCva({ variant, className }))} {...props} ref={ref}>
            {children}
          </a>
        </Link>
      );
    }

    return (
      <a href="#" className={cn(standardLinkCva({ variant, className }))} {...props} ref={ref}>
        {children}
      </a>
    );
  }
);

export default StandardLink;
