import { AppState, RedirectLoginOptions } from "@auth0/auth0-react";

export const handleSignIn = (
  loginWithRedirect: (options?: RedirectLoginOptions) => Promise<void>,
  appState: AppState
) => {
  loginWithRedirect({
    authorizationParams: {
      redirect_uri: window.location.origin + "/handleRedirect",
    },
    appState,
  });
};
