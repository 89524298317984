"use client";

import { useEnterKeyCallback } from "@app/hooks/useEnterKeyCallback";
import { Badge, BadgeText, TextLabel, VariantProps, cn, cva } from "@sourceful/design-system-v3";
import Link from "next/link";
import React from "react";

const accountMenuItemVariant = cva(
  "flex items-center gap-8 px-4 py-8 border-b border-ui-grey-300 rounded-xs focus:focus-ring hover:text-ui-grey-600",
  {
    variants: {
      isLast: {
        true: "border-b-0",
      },
    },
  }
);

interface AccountMenuItemProps extends VariantProps<typeof accountMenuItemVariant> {
  text: string;
  link?: string;
  icon: JSX.Element;
  count?: number;
  onClick?: () => void;
}

const AccountMenuItem = React.forwardRef<HTMLAnchorElement, AccountMenuItemProps>(
  ({ text, link, icon, count, isLast, onClick, ...props }, ref) => {
    const onEnterKey = useEnterKeyCallback(() => onClick?.());

    return (
      <Link href={link ?? ""} prefetch={false} passHref legacyBehavior>
        <a
          className={cn(accountMenuItemVariant({ isLast }))}
          {...props}
          ref={ref}
          onClick={onClick}
          onKeyDown={onEnterKey}
        >
          {icon}
          <TextLabel size="large" weight="medium">
            {text}
          </TextLabel>
          {typeof count === "number" && count > 0 && (
            <Badge variant="filled" colour="brandBlue" size="sm" className="ml-auto">
              <BadgeText>{count}</BadgeText>
            </Badge>
          )}
        </a>
      </Link>
    );
  }
);

export default AccountMenuItem;
