import axios, { AxiosError, AxiosRequestConfig, AxiosRequestHeaders } from "axios";

import axiosRetry from "axios-retry";
import { setupCache } from "axios-cache-adapter";

export const cache = setupCache({
  maxAge: 15 * 60 * 1000,
  exclude: {
    methods: ["put", "patch", "delete"],
  },
  limit: 100,
});

export const createAxiosClient = (baseUrl: string, onError?: (error: AxiosError) => void) => {
  const getAxiosClient = (
    token?: string,
    overrides?: Partial<AxiosRequestConfig>,
    headerOverrides?: Partial<AxiosRequestHeaders>,
    useCache?: boolean,
    retry: boolean = true
  ) => {
    const defaultOptions: AxiosRequestConfig = {
      baseURL: baseUrl,
      headers: {
        "Content-Type": "application/json",
        authorization: token ? `Bearer ${token}` : "",
        ...headerOverrides,
      },
      ...overrides,
    };

    if (useCache) {
      defaultOptions.adapter = cache.adapter;
    }

    const instance = axios.create(defaultOptions);

    instance.interceptors.response.use(
      response => response,
      error => {
        if (typeof error.response === "undefined") {
          console.error("Axios response is undefined/0");
        }
        onError?.(error);
        return Promise.reject(error);
      }
    );

    if (retry) {
      axiosRetry(instance, {
        retries: 3,
        retryCondition: () => {
          // retry doesn't work unless this is set
          return true;
        },
      });
    }

    return instance;
  };

  return getAxiosClient;
};

export default createAxiosClient;
