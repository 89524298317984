import { ErrorStatus, useErrorContext } from "@app/providers/ErrorProvider/ErrorProvider";
import { useEffect } from "react";

export interface UseHandleQueryErrorProps {
  error: Error | null;
  scope: string;
  status: ErrorStatus;
  message: string;
  type?: "report" | "push";
}
export const useHandleQueryError = ({
  error,
  scope,
  status,
  message,
  type = "push",
}: UseHandleQueryErrorProps): void => {
  const { pushError, reportError } = useErrorContext();

  useEffect(() => {
    if (!error) return;

    if (type === "push") {
      pushError(
        scope,
        {
          status,
          message,
          stack: error,
        },
        true
      );
    } else {
      reportError({
        message,
        stack: error,
        status,
      });
    }
  }, [error, pushError, scope, status, message, type, reportError]);
};
