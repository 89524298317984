"use client";

import { Button, ButtonText } from "@sourceful/design-system-v3";
import Cookies from "js-cookie";
import Link from "next/link";
import { useEffect, useState } from "react";
import env from "../../../lib/utils/env";

const SPRING_URL = env("SPRING_URL");

export default function HeaderActionsSpring() {
  const [showYourDesigns, setShowYourDesigns] = useState(false);

  useEffect(() => {
    setShowYourDesigns(
      Cookies.get("login_email") !== undefined && Cookies.get("login_hash") !== undefined
    );
  }, []);

  return (
    <div className="flex gap-16 items-center" data-header-actions="">
      {showYourDesigns && (
        <Button variant={"outline"} layout={"iconLeading"} size="md" asChild>
          <Link href={`${SPRING_URL}/designs`}>
            <ButtonText>Your designs</ButtonText>
          </Link>
        </Button>
      )}

      <Button variant={"primary"} size="md" className="max-[500px]:hidden" asChild>
        <a
          href={
            "https://www.sourceful.com/contact?utm_source=spring&utm_medium=spring&utm_campaign=getintouch"
          }
        >
          <ButtonText>Talk to sales</ButtonText>
        </a>
      </Button>
    </div>
  );
}
