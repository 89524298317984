"use client";

import { useWindowSize } from "@react-hook/window-size";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTrigger,
  IconNavigationMenu,
  TextBody,
  Tooltip,
  TooltipContent,
  TooltipTitle,
  TooltipTrigger,
} from "@sourceful/design-system-v3";
import React, { useEffect, useState } from "react";
import { SanityPrimaryNavigation } from "../../../../groq/siteHeaderConfig";
import BurgerMenuItem from "./BurgerMenuItem";

interface BurgerMenuProps {
  data: SanityPrimaryNavigation | undefined;
}

const BurgerMenu = React.forwardRef<HTMLButtonElement, BurgerMenuProps>(
  ({ data, ...props }, ref) => {
    const [menuOpen, setMenuOpen] = useState(false);

    // Track window size so we can close if it changes, defaults to 0 on SSR
    const [width] = useWindowSize();

    useEffect(() => {
      if (width > 0) {
        setMenuOpen(false);
      }
    }, [width]);

    return (
      <>
        <Dialog open={menuOpen} onOpenChange={open => setMenuOpen(open)}>
          <Tooltip>
            <TooltipTrigger asChild>
              <DialogTrigger asChild>
                <Button
                  size="md"
                  variant="tertiary"
                  layout="iconOnly"
                  className="ml-auto text-inherit hover:text-ui-darkest lg:hidden duration-300"
                  ref={ref}
                  onClick={() => setMenuOpen(!menuOpen)}
                  aria-label="Open menu"
                  data-burger-menu-button=""
                  {...props}
                >
                  <IconNavigationMenu />
                </Button>
              </DialogTrigger>
            </TooltipTrigger>
            <TooltipContent>
              <TooltipTitle text={"Open menu"} />
            </TooltipContent>
          </Tooltip>
          <DialogContent className="w-screen h-full max-w-none rounded-none">
            <TextBody size="small" weight="medium" asChild>
              <nav className="my-32 mx-20">
                <ul>
                  {data?.menuItems.map(menuItem => {
                    return (
                      <li key={menuItem._key}>
                        <BurgerMenuItem data={menuItem} />
                      </li>
                    );
                  })}
                </ul>
              </nav>
            </TextBody>
          </DialogContent>
        </Dialog>
      </>
    );
  }
);

export default BurgerMenu;
