import { KeyboardEventHandler, useCallback } from "react";

export function useEnterKeyCallback(callback: VoidFunction) {
  const handler: KeyboardEventHandler = useCallback(e => {
    if (e.key !== "Enter") {
      return;
    }

    callback();
  }, []);

  return handler;
}
