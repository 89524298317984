import {
  Button,
  ButtonText,
  Flex,
  IconNavigationRevision,
  TextBody,
} from "@sourceful/design-system-v3";
import { FC } from "react";

export interface RecentSearchesProps {
  recentSearches: string[];
  onSelectRecentSearch: (searchText: string) => void;
  onClearRecentSearch: (searchText: string) => void;
  onClearAllRecentSearches: () => void;
}

export const RecentSearches: FC<RecentSearchesProps> = ({
  recentSearches,
  onSelectRecentSearch,
  onClearRecentSearch,
  onClearAllRecentSearches,
}) => {
  if (recentSearches.length === 0) {
    return null;
  }

  return (
    <div className="flex flex-col gap-16">
      <div className="flex justify-between items-center">
        <TextBody size="medium" asChild>
          <h3>Recent searches</h3>
        </TextBody>

        <Button
          size={"md"}
          variant={"tertiary"}
          className={"text-ui-blue-500 underline"}
          onClick={onClearAllRecentSearches}
        >
          <ButtonText>Clear All</ButtonText>
        </Button>
      </div>
      <ul>
        {recentSearches.map(recentSearch => (
          <li key={recentSearch}>
            <Flex justify={"between"}>
              <Button
                onClick={() => onSelectRecentSearch(recentSearch)}
                variant={"tertiary"}
                layout="iconLeading"
                size="md"
              >
                <IconNavigationRevision />
                <ButtonText>{recentSearch}</ButtonText>
              </Button>
              <Button
                size={"md"}
                variant={"tertiary"}
                className={"text-ui-blue-500 underline"}
                onClick={() => onClearRecentSearch(recentSearch)}
              >
                <ButtonText>Clear</ButtonText>
              </Button>
            </Flex>
          </li>
        ))}
      </ul>
    </div>
  );
};
